import React, { createContext, useEffect, useState, useContext } from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import './App.scss';
import { Store } from './services/store';
import runOneSignal from './onesignalInit'

export const loading = () => <div className="animated fadeIn pt-3 text-center"><div className="sk-spinner sk-spinner-pulse"></div></div>;

// const loading = () => <div className="animated fadeIn pt-3 text-center"><div className="sk-spinner sk-spinner-pulse"></div></div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));
const UserLayout = React.lazy(() => import('./containers/UserLayout'));
const AuthLayout = React.lazy(() => import('./containers/AuthLayout'));



const App = () => {
  const { user } = useContext(AppContext);
  useEffect(() => {
    runOneSignal();
  });
  const rightRouteByRole = (role) => {
    if (role === "admin")
      return <Redirect to='admin/dashboard' />;
    if (role === "user")
      return <Redirect to='user/patient' />;
    return <Redirect to='auth/login' />;

  }
  return (
    <HashRouter>
      <React.Suspense fallback={loading()}>
        <Switch>
          <Route path="/admin" name="Admin" render={props => <DefaultLayout {...props} />} />
          <Route path="/user" name="User" render={props => <UserLayout {...props} />} />
          <Route path="/auth" name="Auth" render={props => <AuthLayout {...props} />} />
          {rightRouteByRole(user && user.role)}
        </Switch>
      </React.Suspense>
    </HashRouter>
  );
}

export const AppContext = createContext();

export default () => {
  const _user = Store.getUser();
  const [user, setUser] = useState({ isLoggedIn: _user && true, ..._user });
  const [patients, _setPatients] = useState(Store.get('patients', []));

  const setPatients = (patients) => {
    Store.set('patients', patients);
    _setPatients(patients)
  }
  return (<AppContext.Provider value={{ user, setUser, patients, setPatients }}>
    <App />
  </AppContext.Provider>)
};
